<template>
  <div class="activeIndex">
    <div class="header1">
      <div class="header1_inner">
        <img src="../assets/activityIndex/LOGO.png" alt="">
      </div>
    </div>
    <div class="banner">
      <el-carousel :height="imgHeight" :autoplay="isAuto" ref="carousel">
        <el-carousel-item
          v-for="(item, index) in banners" :key="index + 'q'"
        >
          <img 
            style="width:100%"
            ref="imgH"
            @load="imgLoad"
            class="message-img"
            :src="yuming + item.news_img"
            alt=""
            srcset=""
          />
        </el-carousel-item>
      </el-carousel>
    </div>
   <!-- 初识 start -->
    <div>
      <div class="container_box">
        <div class="list_group" style="padding-top:76px;padding-bottom:0;">
          <div class="left">
          </div>
          <ul class="right">
            <a href="javascript:;">
              <li>
                <div class="title">
                  <div>R</div>
                  <div>E</div>
                  <div>N</div>
                  <div class="txt" style="color: #357bfd">{{chushi[0].news_title}}</div>
                </div>
                <div class="con">
                  {{chushi[0].news_scontent}}
                </div>
                <a href="/deta?id=89" class="lookLink">查看详情</a>
              </li>
            </a>
            <a href="javascript:;">
              <li><img src="../assets/activityIndex/chushi-2.jpg" alt=""></li>
            </a>
            <a href="javascript:;">
              <li>
                <div class="title">
                  <div>Y</div>
                  <div></div>
                  <div>I</div>
                  <div class="txt" style="color: #fcac13">{{chushi[1].news_title}}</div>
                </div>
                <div class="con">
                  {{chushi[1].news_scontent}}
                </div>
                <a href="/deta?id=90" class="lookLink">查看详情</a>
              </li>
            </a>
            <a href="javascript:;">
              <li><img src="../assets/activityIndex/chushi-4.jpg" alt=""></li>
            </a>
            <a href="javascript:;">
              <li>
                <div class="title">
                  <div>L</div>
                  <div></div>
                  <div>I</div>
                  <div class="txt" style="color: #38cba7">{{chushi[2].news_title}}</div>
                </div>
                <div class="con">
                  {{chushi[2].news_scontent}}
                </div>
                <a href="/deta?id=91" class="lookLink">查看详情</a>
              </li>
            </a>
            <a href="javascript:;">
              <li><img src="../assets/activityIndex/chushi-6.jpg" alt=""></li>
            </a>
            <a href="javascript:;">
              <li>
                <div class="title">
                  <div>Z</div>
                  <div>H</div>
                  <div>I</div>
                  <div class="txt" style="color: #fd6d35">{{chushi[3].news_title}}</div>
                </div>
                <div class="con">
                  {{chushi[3].news_scontent}}
                </div>
                <a href="/deta?id=92" class="lookLink">查看详情</a>
              </li>
            </a>
            <a href="javascript:;">
              <li><img src="../assets/activityIndex/chushi-8.jpg" alt=""></li>
            </a>
            <a href="javascript:;">
              <li>
                <div class="title">
                  <div>X</div>
                  <div>I</div>
                  <div>N</div>
                  <div class="txt" style="color: #4fb5d2">{{chushi[4].news_title}}</div>
                </div>
                <div class="con">
                  {{chushi[4].news_scontent}}
                </div>
                <a href="/deta?id=93" class="lookLink">查看详情</a>
              </li>
            </a>
          </ul>
        </div>
      </div>
    </div>
    <!-- 初识 end -->

    <!-- 国学小课堂 start -->
    <div style="margin-top: 65px;">
      <div class="container_box">
        <div class="yx" style="height: 175px;padding-bottom:0;">
          <div class="yx_tit" style="height: 100%;">
            <img class="guoxuexiaoketangImg" src="../assets/activityIndex/guoxuexiaoketang-biaoti.png" alt="" />
          </div>
        </div>
        
        <div class="xkt_main">
          <div>
            <div class="qiehuan">
              <span
                :class="actives == item.menuid ? 'active' : 'noactive'"
                @click="active(item)"
                v-for="(item, key) in catelist"
                :key="key"
                >{{ item.name }}</span
              >
            </div>

            <div class="listCate">
                <div class="tablist" >
                    <ul>
                        <a href="javascript:;" v-for="(item,index) in tablist.slice(0,8)" :key="index">
                            <li @click="goDetail(item.id)">
                                <div class="pic">
                                    <img :src="yuming + item.news_img" alt="">
                                </div>
                                <div class="tab_tit">
                                    {{item.news_title}}
                                </div>
                            </li>
                        </a>
                        <a href="javascript:;" v-for="(item,index) in tablist.slice(8)" :key="index+'s'" v-show="moreShow">
                            <li @click="goDetail(item.id)">
                                <div class="pic">
                                    <img :src="yuming + item.news_img" alt="">
                                </div>
                                <div class="tab_tit">
                                    {{item.news_title}}
                                </div>
                            </li>
                        </a>
                    </ul>
                    
                    <a href="javascript:;" class="xkt_more" v-if="tablist.length>0" @click="ktmore()">查看更多</a>
                </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <!-- 国学小课堂 end -->

    <!-- 活动预告 start -->
    <div class="botom">
        <div class="container_box">
          <div class="yx" style="height:185px;padding-bottom:0;">
            <div class="yx_tit">
                <img src="../assets/activityIndex/huodongyugao-biaoti.png" alt="">
            </div>
          </div>
        </div>
        <div class="yg">
          <ul>
            <div style="max-width:1604px;display:flex;flex-wrap:wrap;margin:0 auto;padding:0 50px;box-sizing:border-box;  ">
              <li v-for="(item,index) in yglist.slice(0,4)" :key="index+'w'" @click="goDetail(item.id)">
                  <div class="yg_pic">
                      <img :src="yuming + item.news_img" alt="">
                  </div>
                  <div class="yg_txt">
                      {{item.news_title}}
                  </div>
                  <div class="yg_date">{{item.news_time}}</div>
              </li>
            </div>
              <div class="dashLine" v-if="yglist.length != 0"></div>
          </ul>
          <ul v-show="ygShow">
            <div style="max-width:1604px;display:flex;flex-wrap:wrap;margin:0 auto;margin-top: 65px;padding:0 50px;box-sizing:border-box;  ">
              <li v-for="(item,index) in yglist.slice(4)" :key="index+'m'" @click="goDetail(item.id)">
                  <div class="yg_pic">
                      <img :src="yuming + item.news_img" alt="">
                  </div>
                  <div class="yg_txt">
                      {{item.news_title}}
                  </div>
                  <div class="yg_date">{{item.news_time}}</div>
              </li>
              </div>
              <div class="dashLine"></div>
          </ul>
          <div class="yj_lookmore_bottom" v-if="yglist.length != 0">
          </div>
        </div>
    </div>
    <!-- 活动预告 end -->

    
    <!-- 影像 start -->
    <div>
      <div class="container_box">
        <div class="yx">
          <div class="yx_tit yx_tit_box">
            <img class="yx_txtImg" src="../assets/activityIndex/yingxiang-biaoti.png" alt="" />
          </div>
          <ul class="yx_group">

            <a href="/deta?id=94">
              <li>
                <img class="yx_txtImg" src="../assets/activityIndex/yingxiang-ren.png" alt="">
                <div class="yx_hover">
                  <img class="searchBtn" src="../assets/activityIndex/chankantubiao.png" alt="" />
                  <div class="lookPics"><img src="../assets/activityIndex/chakanxiangce.png" alt=""></div>
                </div>
              </li>
            </a>
            <a href="/deta?id=95">
              <li>
                <img class="yx_txtImg" src="../assets/activityIndex/yingxiang-yi.png" alt="">
                <div class="yx_hover">
                  <img class="searchBtn" src="../assets/activityIndex/chankantubiao.png" alt="" />
                  <div class="lookPics"><img src="../assets/activityIndex/chakanxiangce.png" alt=""></div>
                </div>
              </li>
            </a>
            <a href="/deta?id=96" style="width: 20.03%;">
              <li>
                <img class="yx_txtImg" src="../assets/activityIndex/yingxiang-li.png" alt="">
                <div class="yx_hover">
                  <img class="searchBtn" src="../assets/activityIndex/chankantubiao.png" alt="" />
                  <div class="lookPics"><img src="../assets/activityIndex/chakanxiangce.png" alt=""></div>
                </div>
              </li>
            </a>
            <a href="/deta?id=97">
              <li>
                <img class="yx_txtImg" src="../assets/activityIndex/yignxiang-zhi.png" alt="">
                <div class="yx_hover">
                  <img class="searchBtn" src="../assets/activityIndex/chankantubiao.png" alt="" />
                  <div class="lookPics"><img src="../assets/activityIndex/chakanxiangce.png" alt=""></div>
                </div>
              </li>
            </a>
            <a href="/deta?id=98">
              <li>
                <img class="yx_txtImg" src="../assets/activityIndex/yignxiang-xin.png" alt="">
                <div class="yx_hover">
                  <img class="searchBtn" src="../assets/activityIndex/chankantubiao.png" alt="" />
                  <div class="lookPics"><img src="../assets/activityIndex/chakanxiangce.png" alt=""></div>
                </div>
              </li>
            </a>
          </ul>
        </div>
      </div>
    </div>
    <!-- 影像 end -->

    <!-- 活动集锦 start -->
    <div class="huodong-beijing">
      <div class="container_box">
        <div class="yx" style="height: 180px;padding-bottom:30px;">
          <div class="yx_tit">
            <img src="../assets/activityIndex/huodongjijin-biaoti.png" alt="" />
          </div>
        </div>

        <ul class="yj">
          <li>
            <div class="yj_li hd1">
              <div class="txt_shadow">顺义区博物馆开展“仁义礼智信”系列主题活动--“仁”字篇</div>
              <a href="/deta?id=99" class="yj_hover">
                <img src="../assets/activityIndex/chankantubiao.png" alt="" />
                <div class="lookPics">查看详情</div>
              </a>
            </div>
          </li>
          <li style="width: 810px">
            <div class="yj_center_top">
              <div class="yj_li hd2" style="width: 49.3%">
                <div class="yj_center_left">
                  <div class="txt_shadow" style="line-height:30px;height: auto;">顺义区博物馆开展“仁义礼智信”系列主题活动——“信”字篇</div>
                  <a href="/deta?id=100" class="yj_hover" style="padding-top: 60px">
                    <img src="../assets/activityIndex/chankantubiao.png" alt="" />
                    <div class="lookPics">查看详情</div>
                  </a>
                </div>
              </div>
              <div class="yj_li hd3" style="width: 49.3%">
                <div class="yj_center_left">
                  <div class="txt_shadow">顺义区博物馆开展“仁义礼智信”系列主题活动--“义”字篇</div>
                  <a href="/deta?id=101" class="yj_hover" style="padding-top: 37px">
                    <img src="../assets/activityIndex/chankantubiao.png" alt="" />
                    <div class="lookPics">查看详情</div>
                  </a>
                </div>
              </div>
            </div>
            <div class="yj_li hd4" style="height:260px;">
              <div
                class="yj_center_bottom"
                style="width: 100%; height: 100%;"
              >
                <div class="txt_shadow">顺义区博物馆开展“仁义礼智信”系列主题活动——“礼”字篇</div>
                <a href="/deta?id=102" class="yj_hover" style="padding-top: 35px">
                  <img src="../assets/activityIndex/chankantubiao.png" alt="" />
                  <div class="lookPics">查看详情</div>
                </a>
              </div>
            </div>
          </li>
          <li>
            <div class="yj_li hd5">
              <div class="txt_shadow">顺义区博物馆开展“仁义礼智信”系列主题活动——“智”字篇</div>
              <a href="/deta?id=167" class="yj_hover">
                <img src="../assets/activityIndex/chankantubiao.png" alt="" />
                <div class="lookPics">查看详情</div>
              </a>
            </div>
          </li>
        </ul>
        <div class="yj_lookmore_bottom hdjj_look_box">
        </div>
      </div>
    </div>
    <!-- 活动集锦 end -->

    
    <!-- 活动征集 start -->
    <div>
      <div class="container_box">
        <div class="yx" style="height: 175px;padding-bottom:40px;margin-top: 60px;">
          <div class="yx_tit" style="height: 100%;">
            <img src="../assets/activityIndex/huodognzhengji-biaoti.png" alt="" />
          </div>
        </div>
        <div class="zj_group" style="padding-bottom:60px;">
          <div class="zj_group_left">
            <a href="javascript:;" @click="goDetail(hdzjList[0].id)">
              <img :src="yuming + hdzjList[0].news_img" alt="">
              <div class="yj_hover">
                <div class="yj_hover_center">
                  <img src="../assets/activityIndex/chankantubiao.png" alt="" />
                  <div class="lookPics">查看详情</div>
                </div>
              </div>
            </a>
          </div>
            
          <div class="zj_group_right">
              <ul>
                <a href="javascript:;" v-for="(item,index) in hdzjList.slice(1)" :key="index+'x'">
                    <li @click="goDetail(item.id)">
                        <img :src="yuming + item.news_img" alt="">
                        <div class="yj_hover">
                          <div class="yj_hover_center">
                            <img src="../assets/activityIndex/chankantubiao.png" alt="" />
                            <div class="lookPics">查看详情</div>
                          </div>
                        </div>
                    </li>
                </a>
                  
              </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- 活动征集 end -->

    <div class="footers">
        <div class="footer_con">
            <img src="../assets/login/logo.png" alt="">
            <div class="footer_con_line"></div>
            <img src="../assets/qrCode.png" alt="">
        </div>
        <div class="footer_base"></div>
    </div>
  </div>
</template>

<script>
import { fetch } from "../api/https.js";
import { Message } from 'element-ui'
export default {
  data() {
    return {
      isAuto: true,
      imgHeight:'0',
      banners: [],
      chushi:[],
      moreShow:false,
      actives: 37,
      //tab按钮
      catelist: [
        { menuid: 37, name: "仁" },
        { menuid: 42, name: "义" },
        { menuid: 43, name: "礼" },
        { menuid: 44, name: "智" },
        { menuid: 45, name: "信" },
      ],
      yxArr:[], //影像
      hdjjList:[], //活动集锦
      tablist:[], //国学小课堂
      moseoverIndex:0,
      hdzjList:[], //活动征集
      ygShow:false,
      yglist:[], //活动预告
      yuming:"https://jiekou.symuseum.cn"
    };
  },
  mounted(){
    console.log(this.yuming)
    this.getBanner()

    this.imgLoad();
    window.addEventListener("resize",this.imgLoad,false);

    this.getChushi()
    this.getYingxiang()
    this.getHDJJ()
    this.getGxxkt()
    this.getHdzj()
    this.getHdyg()
  },
  destroyed(){
     window.removeEventListener("resize",this.imgLoad,false);
  },
  methods: {
    active(value) {
      this.actives = value.menuid;
      this.getGxxkt(this.actives)
    },
    ktmore(){
      if(this.tablist.length>8){
        this.moreShow = true
      }else{
       Message({
        message:"暂时没有更多数据啦！",
        type:'warning'
       })
        // alert("暂时没有更多数据啦！")
      }
    },
    choice:function(i) {
      this.moseoverIndex=i;		
    },
    // 活动预告 查看更多
    ygMore(){
      if(this.yglist.length>4){
        this.ygShow = true
      }else{
        alert("暂时没有更多数据啦！")
      }
    },

    // 获取banner
    imgLoad(){
      this.$nextTick(()=>{
        this.imgHeight = `${this.$refs.imgH[0].naturalHeight}px`;
        console.log(this.imgHeight,"图片高度")
      })
    },
    getBanner(){
      let that = this
      fetch("/getnewslist/menuid/33")
        .then((res) => {
          that.banners = res.data.list
        })
        .catch((err) => {});
    },
    // 初识
    getChushi(){
      let that = this
      fetch("/getnewslist/menuid/34")
        .then((res) => {
          if(res.data.list.length > 0){
            that.$nextTick(() => {
              that.chushi = res.data.list
            })
          }
        })
        .catch((err) => {});
    },
    // 影像
    getYingxiang(){
      let that = this
      fetch("/getnewslist/menuid/35")
        .then((res) => {
          if(res.data.list.length > 0){
            that.yxArr = res.data.list
          }
        })
        .catch((err) => {});
    },
    // 活动集锦
    getHDJJ(){
      fetch("/getnewslist/menuid/36")
        .then((res) => {
          if(res.data.list.length > 0){
            
            this.hdjjList = res.data.list
          }
        })
        .catch((err) => {});
    },
    // 国学小课堂
    getGxxkt(){
      fetch("/getnewslist/menuid/" + this.actives)
        .then((res) => {
          this.tablist = [] 
          if(res.data.list.length > 0){
            this.tablist = res.data.list
          }
        })
        .catch((err) => {});
    },
    // 活动征集
    getHdzj(){
      fetch("/getnewslist/menuid/38")
        .then((res) => {
          if(res.data.list.length > 0){
            this.hdzjList = res.data.list
          }
        })
        .catch((err) => {});
    },
    // 活动预告
    getHdyg(){
      fetch("/getnewslist/menuid/39")
        .then((res) => {
          // console.log(res)
          if(res.data.list.length > 0){
            let ygListDate = ""
            let ygList = []
            res.data.list.map(function(item){
              let dateStr = item.news_time * 1000
              let date = new Date(dateStr) //时间戳为10位需*1000，时间戳为13位的话不需乘1000
              // console.log(date)
              let M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
              let D = date.getDate() + ' ';
              ygListDate = M + D
              // console.log("日期：",ygListDate)
              ygList.push({
                id: item.id,
                news_time: ygListDate,
                news_img: item.news_img,
                news_title: item.news_title,
                news_scontent: item.news_scontent
              })
            })
            this.yglist = ygList 

          }
        })
        .catch((err) => {});
    },
    goDetail(value) {
      this.$router.push(`/deta?id=${value}`)
    }
  },
};
</script>

<style scoped>
.Scales {
  transform: scaleX(0.9);
}
.activeIndex {
  width: 100%;
}
.header1{
  width: 100%;
  height: 97px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  background: #fff;
}
.header1_inner{
  display: flex;
  align-items: center;
  /* max-width: 1604px; */
  /* width: 86%; */
  width: 1604px;
  height: 100%;
  padding: 0 50px;
  box-sizing: border-box;
  margin: 0 auto;
}

.header1_inner img{
  width: 577px;
}
.banner {
  /* height: 700px; */
  /* height: 877px; */
  width: 100%;
  margin-top: 97px;
  border-top: 1px solid #ccc;
  /* background: pink; */
}
.banner img {
  height: 100%;
  width: 100%;
}
.container_box {
  max-width: 1604px;
  /* width: 1604px; */
  /* width: 86%; */
  margin: 0 auto;
  margin-bottom: 40px;
  padding: 0 50px;
  box-sizing: border-box;
  /* background: #fff; */
}
.list_group {
  display: flex;
  padding-bottom: 60px;
}
.list_group .left {
  min-width: 246px;
  width: 20%;
  padding: 20px 20px;
  box-sizing: border-box;
  /* background: #3384dd; */
  background: url('../assets/activityIndex/chushirenyilizhixin_.jpg');
  background-size: 100% 100%;
}
.list_group .left .left_shadow {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
  height: 100%;
  padding-right: 30px;
  padding-bottom: 45px;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.5);
}
.list_group .left .left_shadow .column_txt{
  writing-mode: vertical-lr;
  letter-spacing: 5px;
  font-size: 18px;
  color: #fff;
}
.list_group .left .left_shadow  .column_txt i{
  display: inline-block;
  transform: rotate(18deg);
}
.list_group .right {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
}
.list_group .right a {
  display: block;
  width: 100%;
  width: 33.3%;
  height: 300px;
  /* height: 240px; */
}
.list_group .right a li {
  width: 100%;
  height: 100%;
}
.list_group .right a:nth-child(odd) {
  /* 奇数 */
  background: #ebecf0;
  position: relative;
}
.list_group .right a:nth-child(even) {
  /* 偶数 */
  background: #574745;
}
.list_group .right a li .title {
  padding: 20px 0;
  padding-bottom: 0;
  margin-top: 20px;
  box-sizing: border-box;
  text-align: center;
  position: relative;
}
.list_group .right a li .title div {
  width: 30px;
  display: inline-block;
  color: #a4a5a8;
  font-size: 16px;
}
.list_group .right a li .title .txt {
  font-size: 28px;
  font-weight: 600;
  position: absolute;
  top: 68%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.list_group .right a li .con {
  display: flex;
  align-items: center;
  height: 163px;
  color: #999;
  font-size: 18px;
  line-height: 25px;
  padding: 0 36px;
  box-sizing: border-box;
  letter-spacing: 1px;
}
.list_group .right a li img{
    width: 100%;
    height: 100%;
}
.list_group .right a li .lookLink {
  display: block;
  height: 36px;
  color: #fff;
  text-align: center;
  line-height: 36px;
  padding: 0 18px;
  box-sizing: border-box;
  border-radius: 50px;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background: #afb5c6;
}
.yx {
  width: 100%;
  padding-bottom: 60px;
}
.yx .yx_tit {
  height: 100%;
  height: 210px;
  position: relative;
}
.yx .yx_tit img {
  display: block;
  /* width: 346px; */
  /* width: 215px; */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.yx_group {
  display: flex;
  width: 100%;
}
.yx_group a {
  display: block;
  width: 20%;
  height: 500px;
  /* height: 400px; */
  color: #fff;
  position: relative;
}
.yx_group a li {
  width: 100%;
  height: 100%;
}
.yx_group a:first-child{
  background: url('../assets/activityIndex/ren-beijing.jpg') no-repeat;
  background-size: 100% 100%;
}
.yx_group a:nth-child(2){
  background: url('../assets/activityIndex/yi-beijing.jpg') no-repeat;
  background-size: 100% 100%;
}
.yx_group a:nth-child(3){
  background: url('../assets/activityIndex/li-biejing.jpg') no-repeat;
  background-size: 100% 100%;
}
.yx_group a:nth-child(4){
  background: url('../assets/activityIndex/zhi-biejing.jpg') no-repeat;
  background-size: 100% 100%;
}
.yx_group a:last-child{
  background: url('../assets/activityIndex/xin-beijing.jpg') no-repeat;
  background-size: 100% 100%;
}
.yx_group a li .yx_txtImg{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}
.yx_group a:hover .yx_txtImg{
  opacity: 0;
}
.yx_group a li .yx_hover{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  background: rgba(0, 0, 0, 0.45);
  opacity: 0;
}
.yx_group a li .searchBtn{
  display: block;
  margin: 0 auto;
  margin-bottom: 50px;
}
.yx_group a:hover .yx_hover{
  opacity: 1;
}
.huodong-beijing{
  height: 834px;
  background: url('../assets/activityIndex/huodong-beijing.jpg') no-repeat;
  background-size: 100% 100%;
}
.yj {
  display: flex;
  width: 100%;
}
.yj li{
  width: 400px;
  /* width: 23.5%; */
  height: 553px;
  /* height: 445px; */
  margin-right: 10px;
  position: relative;
}
.yj li:last-child {
  margin-right: 0;
}
.yj li .yj_li{
  height: 100% ;
  /* background: url('../assets/fo.png') no-repeat;
  background-size: 100% 100%; */
}
.yj li .hd1{
  background: url('../assets/activityIndex/hd-1.png') no-repeat;
  background-size: 100% 100%;
}
.yj li .hd2{
  background: url('../assets/activityIndex/hd-2.png') no-repeat;
  background-size: 100% 100%;
}
.yj li .hd3{
  background: url('../assets/activityIndex/hd-3.png') no-repeat;
  background-size: 100% 100%;
}
.yj li .hd4{
  background: url('../assets/activityIndex/hd-4.png') no-repeat;
  background-size: 100% 100%;
}
.yj li .hd5{
  background: url('../assets/activityIndex/hd-5.png') no-repeat;
  background-size: 100% 100%;
}
.yj li img {
  width: 100%;
  height: 100%;
}
.yj li .txt_shadow {
  width: 230px;
  /* width: 190px; */
  /* height: 40px; */
  color: #fff;
  font-size: 16px;
  text-align: center;
  line-height: 40px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  /* border-radius: 50px; */
  position: absolute;
  bottom: 22px;
  left: 50%;
  transform: translateX(-50%);
}
.yj li .yj_hover {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.45);
  opacity: 0;
}
.yj li .yj_li:hover .yj_hover {
  opacity: 1;
}
.yj li .yj_li:hover:hover .txt_shadow {
  opacity: 0;
}
.yj li .yj_li .yj_hover img {
  display: block;
  width: 42px;
  height: 42px;
  margin: 0 auto;
  margin-bottom: 50px;
}
.yj li .yj_hover .lookPics {
  width: 150px;
  height: 40px;
  color: #fff;
  text-align: center;
  line-height: 40px;
  margin: 0 auto;
  border-radius: 50px;
  background: rgba(0, 98, 216, 0.7);
}
.yj_center_top {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 283px;
  /* height: 235px; */
  margin-bottom: 10px;
}
.yj_center_bottom {
  position: relative;
}
.yj_center_top .yj_center_left {
  height: 100%;
  position: relative;
}
.yj_lookmore_bottom {
  width: 100%;
  height: 128px;
  /* margin-top: 95px; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.yj_lookmore_bottom .yj_lookMore {
  display: block;
  width: 195px;
  height: 60px;
  color: #fff;
  font-size: 16px;
  text-align: center;
  line-height: 60px;
  border: 1px solid #fff;
  border-radius: 50px;
}

.xkt_main {
  width: 100%;
}
.qiehuan {
  height: 43px;
  width: 100%;
  margin-right: 65px;
  margin-bottom: 60px;
  color: #bd955d;
  display: flex;
  justify-content: center;
}
.active {
    width: 90px;
    height: 45px;
    color: white;
    text-align: center;
    line-height: 45px;
    background: #3C77DA;
}
.noactive {
  background: #F3F4FA;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
}
.qiehuan span {
  box-sizing: border-box;
  display: block;
  float: left;
  width: 105px;
  height: 41.7px;
  border-radius: 5px;
  text-align: center;
  line-height: 41.7px;
  margin-right: 40px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  cursor: pointer;
  margin-bottom: 13px;
  border-radius: 50px;
}
.qiehuan span:last-child{
    margin-right: 0;
}
.listCate{
    width: 100%;
}
.tablist {
    width: 100%;

}
.tablist ul{
    display: flex;
    flex-wrap: wrap;
}
.tablist ul a{
    display: block;
    width: 23.6%;
    height: 290px;
    /* height: 235px; */
    margin-right: 20px;
    margin-bottom: 20px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background: #fff;
    border: 1px solid #f1f1f1;
}
.tablist ul a:nth-child(4n){
    margin-right: 0%;
}
.tablist ul a:hover{
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.2);
}
.tablist ul a li{
    width: 100%;
    height: 100%;
}

.tablist ul a li .pic{
    width: 100%;
    height: 215px;
    /* height: 175px; */
    overflow: hidden;
}
.tablist ul a li .pic img{
    width: 100%;
    height: 100%;
    transition: all 0.6s;
}
.tablist ul a .pic:hover img{
    transform: scale(1.1);
}
.tablist ul a li .tab_tit{
    height: 74px;
    /* height: 60px; */
    color: #333;
    padding: 0 20px;
    /* padding-top: 20px; */
    font-size: 20px;
    line-height: 74px;
    box-sizing: border-box;
    white-space:nowrap;
    overflow:hidden;
    text-overflow:ellipsis;
}
.xkt_more{
    display: block;
    width: 205px;
    height: 62px;
    color: #fff;
    font-size: 20px;
    text-align: center;
    line-height: 62px;
    margin: 40px auto;
    background: #3C77DA;
    border-radius: 50px;
}
.zj_group{
    width: 100%;
    height: 721px;
    /* height: 580px; */
    display: flex;
    justify-content: space-between
}
.zj_group_left{
    width: 74%;
    height: 100%;
}
.zj_group_left img{
    width: 100%;
    height: 100%;
}
.zj_group_right{
    width: 24%;
    height: 100%;
}
.zj_group_left a{
    display: block;
    width: 100%;
    height: 721px;
    margin-bottom: 20px;
    position: relative;
}
.zj_group_right a{
    display: block;
    width: 100%;
    height: 227px;
    /* height: 180px; */
    margin-bottom: 20px;
    position: relative;
}
.zj_group a .yj_hover{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  opacity: 0;
}
.zj_group a:hover .yj_hover{
  opacity: 1;
}
.zj_group a .yj_hover img{
  display: block;
  width: 42px;
  height: 42px;
  margin: 0 auto;
  margin-bottom: 30px;
}
.zj_group a .yj_hover .yj_hover_center .lookPics{
  width: 145px;
  height: 45px;
  color: #fff;
  text-align: center;
  line-height: 45px;
  background: rgba(0, 98, 216, 0.7);
  border-radius: 50px;
}
.zj_group_right a:last-child{
    margin-bottom: 0;
}
.zj_group_right a li{
    width: 100%;
    height: 100%;
}
.zj_group_right a li img{
    width: 100%;
    height: 100%;
}

.yg{
    width: 100%;
}
.yg ul {
    position: relative;
}
.yg ul li{
    /* min-width: 25%; */
    min-width: 23.8%;
    width: 284px;
    height: 283px;
    margin-right: 20px;
    margin-bottom: 20px;
    box-sizing: border-box;
    position: relative;
    cursor: pointer;
}

.yg ul li:nth-child(4n){
    margin-right: 0;
    padding-right: 0;
}
.yg .yg_pic{
    width: 100%;
    height: 215px;
    /* height: 175px; */
}
.yg .yg_pic img{
    width: 100%;
    height: 100%;
}
.yg .yg_txt{
    width: 100%;
    height: 60px;
    color: #fff;
    line-height: 60px;
}
.yg .yg_date{
    width: 75px;
    height: 35px;
    color: #333;
    font-size: 18px;
    text-align: center;
    line-height: 35px;
    background: #fff;
    border-radius: 50px;
    position: absolute;
    bottom: -45px;
    /* bottom: 0; */
    left: 0;
    z-index: 2;
}
.dashLine{
    width: 100%;
    height: 1px;
    position: absolute;
    bottom: -10px;
    /* bottom: 35px; */
    left: 0;
    z-index: 1;
    border-bottom: 1px dashed #fff;
}
.botom{
    min-height: 670px;
    /* min-height: 798px; */
    position: relative;
    background: url('../assets/activityIndex/yugao-beijing.jpg') no-repeat;
    background-size: 100% 100%;
    margin-top: 90px;
}
.footers{
    width: 100%;
    height: 323px;
    /* height: 255px; */
    /* background: url('../assets/activityIndex/weibu.png') no-repeat; */
    background-size: 100% 100%;
    margin-top: -1px;
}
.footer_con{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 283px;
    background: #2688FD;
}
.footer_con_line{
    width: 1px;
    height: 45px;
    margin: 0 50px;
    border-left: 1px dashed #fff;
}
.footer_base{
    width: 100%;
    height: 41px;
    background: #0062D8;
}
.hdjj_look_box{
  height: 110px;
  /* height: 215px; */
  margin-top: 10px;
}
.yx_tit_box{
  margin: 52px 0;
  margin-bottom: 30px;
}
.guoxuexiaoketangImg{
  /* width: 420px !important; */
}
</style>

<style>
.header{
    display: none !important;
}
</style>